import React from 'react';
import { Breadcrumbs, Grid, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { EnumColors } from '../../enum/EnumColors';



const useStyles = makeStyles((theme) => ({
    headerMenu: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: EnumColors.YELLOW,
      height: '64px',
    },
    headerSubTitle: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& a': {
        color: EnumColors.WHITE,
        textDecoration: 'none',
        fontWeight: 'bold',
        fontSize: '1.5rem',
        [theme.breakpoints.down('xs')]: {
          fontSize: '1.2rem',
        },
      },
    },
    buttonMenu: {
      color: EnumColors.WHITE,
      textDecoration: 'none',
      fontWeight: 'bold',
      fontSize: '1rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.8rem',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    colorW: {
      color: EnumColors.WHITE,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));


const ProjetosHeader: React.FC = () => {

    const classes = useStyles()

    return (
        <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.headerMenu}>
            <Grid
                item
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.headerSubTitle}
            >
                <Link to={"/"}>
                    MATHEUS CALAÇA
                </Link>
            </Grid>
            <Grid item container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
                xs={12}>
                <Breadcrumbs aria-label="breadcrumb" className={classes.colorW} >
                    <Link to="/" className={classes.buttonMenu} >Home</Link>
                    <Link to="/calcJuros" className={classes.buttonMenu} >Juros</Link>
                    <Link to="/wpp" className={classes.buttonMenu} >Whatsapp</Link>
                    <Link to="/print" className={classes.buttonMenu} >Print</Link>
                    <Link to="/consultaCnpj" className={classes.buttonMenu} >Consulta Cnpj</Link>
                </Breadcrumbs>
            </Grid>

        </Grid>

    );
}


export default ProjetosHeader;