import { Button, Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
import ProjetosHeader from '../components/projetoComponets/ProjetosHeader';
import { EnumColors } from '../enum/EnumColors';
import Carousel from 'react-material-ui-carousel';

const useStyle = makeStyles((theme) => ({
    headerMenu: {
        backgroundColor: EnumColors.YELLOW,
        color: EnumColors.PURPLE_MIDLE,
        fontSize: "20px"
    },
    gridMargin: {
        marginTop: "15px"
    },
    gridPaddingBottom: {
        paddingBottom: "15px"
    },
    headerBt: {
        zIndex: 100,
        padding: "10px 10px 10px",
        background: EnumColors.YELLOW,
        borderRadius: "10px",
        margin: theme.spacing(2),
        '&:hover': {
            backgroundColor: EnumColors.PURPLE_MIDLE,
            color: EnumColors.WHITE
        },
        color: EnumColors.WHITE
    },
    container: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        },
    },
    section: {
        marginBottom: theme.spacing(4),
    },
    sectionProjeto: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
        },
    },
    screenshot: {
        textAlign: 'center',
        '& img': {
            maxWidth: '100%',
            height: 'auto',
        },
    },
    downloadButton: {
        marginTop: theme.spacing(2),
        backgroundColor: EnumColors.PURPLE_MIDLE,
        color: EnumColors.WHITE,
        '&:hover': {
            backgroundColor: EnumColors.YELLOW,
            color: EnumColors.PURPLE_MIDLE,
        },
    },
    carouselContainer: {
        maxHeight: '30%',
        margin: '0 auto',
    },
}));

const ConsultaCnpjPage: React.FC = () => {
    const classes = useStyle();
    const APK_CONSULTA_CNPJ = "../../../resource/apk/consulta-cnpj.apk"

    return (
        <>
            <ProjetosHeader />
            <Grid container direction="column" alignItems="center" className={classes.container}>
                <Grid item xs={12} className={classes.section}>
                    <Typography variant="h1" align="center">Consulta de CNPJ</Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12} className={classes.section}>
                    <Typography variant="h2">Sobre o Projeto</Typography>
                </Grid>
                <Grid item xs={12} className={classes.sectionProjeto}  >

                    <Grid item xs={12} md={8} sm={12} className={classes.section}>
                        <Typography variant="body1">
                            Este projeto foi desenvolvido para consulta de CNPJ, permitindo que os usuários obtenham informações detalhadas sobre empresas. A aplicação foi projetada para funcionar tanto em dispositivos Android quanto na web, proporcionando uma experiência de usuário consistente em ambas as plataformas.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} sm={12} className={classes.section} >
                        <Button
                            variant="contained"
                            className={classes.downloadButton}
                            href={APK_CONSULTA_CNPJ}
                            download
                        >
                            Baixar APK
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.section}>
                    <Typography variant="h2">Funcionalidades</Typography>
                    <ul>
                        <li>Consulta de CNPJ</li>
                        <li>Detalhamento de informações sobre empresas</li>
                        <li>Historico de consulta de CNPJ</li>
                        <li>Compatibilidade com Android e Web</li>
                    </ul>
                </Grid>
                <Grid item xs={12} className={`${classes.section} ${classes.carouselContainer}`}>
                    <Typography variant="h2">Prints da Aplicação</Typography>
                    <Carousel>
                        <div className={classes.screenshot}>
                            <img src="../../../resource/images/consultaCnpj/telainIcial.jpeg" alt="Tela Inicial" height={30} />
                            <Typography variant="body2">Tela Inicial</Typography>
                        </div>
                        <div className={classes.screenshot}>
                            <img src="../../../resource/images/consultaCnpj/paginaDados.jpeg" alt="Tela de Consulta" height={30} />
                            <Typography variant="body2">Tela de Consulta</Typography>
                        </div>
                        <div className={classes.screenshot}>
                            <img src="../../../resource/images/consultaCnpj/paginaMainInfo.jpeg" alt="Tela de Consulta" height={30} />
                            <Typography variant="body2">Tela de mais Informações</Typography>
                        </div>
                        <div className={classes.screenshot}>
                            <img src="../../../resource/images/consultaCnpj/historicoTela.jpeg" alt="Tela de Consulta" height={30} />
                            <Typography variant="body2">Tela de Historico</Typography>
                        </div>
                    </Carousel>
                </Grid>
                <Grid item xs={12} className={classes.section}>
                    <Button
                        variant="contained"
                        className={classes.downloadButton}
                        href={APK_CONSULTA_CNPJ}
                        download
                    >
                        Baixar APK
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default ConsultaCnpjPage;